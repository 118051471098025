import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout } from '../components'
import { theme } from '../styles'

const Hero = styled.div`
  position: absolute;
  overflow: visible;
  z-index: 999;
  top: 0rem;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${props => props.mobile ? 'calc(100vh - 15rem)' : 'calc(100vh - 5rem)'};
  display: flex;
  @media (max-width: ${theme.breakpoints.s}) {
    height: ${props => props.mobile ? '100%' : 'calc(100vh - 8.5rem)'};
  }
  justify-content: center;
`

const HeroImg = styled.img`
  width: auto;
  height: auto;
  position: absolute;
  bottom: 0;
  z-index: 99;
  @media (max-width: ${theme.breakpoints.m}) {
    /* right: 3rem; */
  }
`

const HeroImgLa = styled(HeroImg)`
  display: none;
  width: 50%;
  max-width: 750px;
  height: auto;
  @media (min-width: ${theme.breakpoints.m}) {
    display: block;
  }
`

const HeroImgT = styled(HeroImg)`
  display: none;
  width: 55%;
  height: auto;
  @media (min-width: ${theme.breakpoints.s}) and (max-width: 899px) {
    display: block;
  }
`

const HeroImgM = styled(HeroImg)`
  display: none;
  width: 70%;
  @media (max-width: 599px) {
    display: block;
  }
`

const HeroText = styled.div`
  position: absolute;
  bottom: 25%;
  left: 0rem;
  @media (max-width: ${props => props.theme.maxWidth}){
    left: 3rem;
  }
  @media (max-width: ${theme.breakpoints.m}) {
    left: 1.5rem;
    bottom: 65%;
  }
  h3 {
    word-wrap: break-word;
  }
`

class Index extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mobile: false
    }
  }

  isMobileSafari = () => {
    if (typeof document !== 'undefined') {
      return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
    }
    return false
  }
  
  componentDidMount () {
    if (typeof document !== 'undefined') {
      document.documentElement.style.overflow = 'hidden';
    }
    if (this.isMobileSafari()) {
      this.setState({mobile: true})
    }
  }

  componentWillUnmount () {
    if (typeof document !== 'undefined') {
      document.documentElement.style.overflow = 'visible';
    }
  }
  
  render() {
    // const { data } = this.props
    const { data: { homepage: { data: { hero } } }} = this.props;
    const { mobile } = this.state
    return (
      <Layout shouldRotate={true}>
        <Hero mobile={mobile}>
          <HeroText>
            <h2>
              DÓRÓTHEA<br/>
              ÁRMANN
            </h2>
          </HeroText>
          <HeroImgLa src={hero.url}/>
          <HeroImgT src={hero.Tablet.url}/>
          <HeroImgM src={hero.Mobile.url}/>
        </Hero>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    hero: PropTypes.shape({
      url: PropTypes.string.isRequired,
      dimensions: PropTypes.shape({
        width: PropTypes.string.isRequired,
        height: PropTypes.string.isRequired
      }),
      Tablet: PropTypes.shape({
        url: PropTypes.string.isRequired,
        dimensions: PropTypes.shape({
          height: PropTypes.string.isRequired,
          width: PropTypes.string.isRequired
        })
      }),
      Mobile: PropTypes.shape({
        url: PropTypes.string.isRequired,
        dimensions: PropTypes.shape({
          height: PropTypes.string.isRequired,
          width: PropTypes.string.isRequired
        })
      }),
    }),
    linkedin: PropTypes.shape({
      link_type: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }),
    phone: PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  }).isRequired,
}

export const pageQuery = graphql`
  query AboutQuery {
    homepage: prismicHomepage {
      data {
        email {
          text
        }
        hero {
          url
          dimensions {
            width
            height
          }
          Tablet {
            url
            dimensions {
              height
              width
            }
          }
          Mobile {
            dimensions {
              height
              width
            }
            url
          }
        }
        linkedin {
          link_type
          target
          url
        }
        phone {
          text
        }
      }
    }
  }
`
